.psi-maschinenliste {
    padding: 1rem;
    min-width: fit-content;
}

.psi-maschinenliste input {
    max-width: 110px;
}

.psi-maschinenliste-page input {
    min-width: 110px;
    max-width: 40%;
}

.psi-search-result-info {
    margin-top: 0.6em;
    font-size: smaller;
}

.psi-address-short {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* .psi-maschinenliste-page .maschinen-link {
    border: 2px solid transparent !important;
}
.psi-maschinenliste-page .maschinen-link div {
    background-color: inherit !important;
} */
.machines-list-item {
    @apply cursor-pointer;
}

.machines-list-item:hover {
    @apply !bg-su-primary-50;
}

/* .psi-maschinenliste-page {
    margin-left: auto;
    margin-right: auto;
    min-width: 50%;
} */