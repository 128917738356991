.button-with-icon {
  @apply gap-4;
  @apply bg-white rounded-[6px];
  @apply px-3 py-2;
  @apply border border-solid border-su-gray-300;
  @apply text-su-gray-900;
  font: normal normal 400 12px/110% "Plus Jakarta Sans Variable";
  @apply cursor-pointer;
}

.select-button-with-icon {
  @apply cursor-auto;
}

.button-with-icon select {
  @apply bg-inherit;
  @apply text-inherit;
  font: normal normal 400 12px/110% "Plus Jakarta Sans Variable";
  @apply !cursor-pointer;
  /* hide the drop-down arrow */
  appearance: none;
}

.button-with-icon select option {
  font-family: "Plus Jakarta Sans Variable", sans-serif;
  @apply !cursor-pointer;
}

.button-with-icon svg {
  @apply text-su-gray-500;
}