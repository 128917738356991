table.stoerungen {
    border-collapse: collapse;
}

tr.stoerung-recent + tr.stoerung-old {
    border-top: 1px dashed #aaa;
}

.stoerung-old {
    color: #aaa;
}
