#main-menu {
  position: fixed;
  top: 60px;
  width: 240px !important;
  min-width: 240px !important;
  height: 100vh;
  @apply pl-2;
  overflow: auto;
  @apply bg-white;
  z-index: 99;
}

#main-menu .ps-sidebar-container {
  @apply bg-inherit;
}

#main-menu .ps-menu-button {
  @apply bg-inherit;
  @apply h-11 w-52;
  @apply pl-0 pr-2 py-3;
  @apply text-su-gray-800;
  font: normal normal 600 14px/15.4px "Plus Jakarta Sans Variable";
}

#main-menu .ps-menu-button.active {
  @apply bg-su-gray-50;
  @apply text-su-primary-900;
}

#main-menu .ps-submenu-content .ps-menu-button {
  @apply bg-inherit;
  @apply text-su-gray-800;
  font: normal normal 400 14px/15.4px "Plus Jakarta Sans Variable";
  /* @apply pl-4; */
}