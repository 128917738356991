.badge.online {
  @apply text-[#169425] bg-[#E8FCE3];
}

.badge.offline {
  @apply text-[#941616] bg-[#FCE3E3];
}

.badge.disabled,
.badge.unknown {
  /* color: darkgray;
  background-color: lightgray; */
  @apply !text-purple-500;
}