.App-header {
  @apply fixed;
  display: flex;
  align-items: center;
  height: 60px;
  @apply w-full;
  font-size: calc(10px + 2vmin);
  /* color: var(--color-su-gray); */
  @apply border-b border-[#d9d9d9];
  @apply bg-white;
  z-index: 99;
}

.App-header .logo-container {
  width: 240px;
  /* border-right: 1px solid red; */
}

.App-header .psi-logo {
  height: 27px;
  @apply pl-6;
}

.App-header h1 {
  margin-left: 0;
  margin-right: 1.7em;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 60px;
}

.App-header nav {
  @apply pl-6;
}

.App-header .user-account {
  margin-left: auto;
  margin-right: 0;
}

.content-area {
  @apply absolute left-60 top-[60px];
  width: calc(100vw - 240px);
}
.main-container {
  /* Don't ask where the -80px comes from. It includes the footer height and some margins. It just works very well. */
  min-height: calc(100vh - 60px - 80px) !important;
}