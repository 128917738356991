/** Colors */
@theme {
  /* su-primary are hues of blue */
  --color-su-primary-50: #E3F1FC;
  --color-su-primary-100: #BADBF7;
  --color-su-primary-200: #90C5F2;
  --color-su-primary-300: #65AEED;
  --color-su-primary-400: #479DEA;
  --color-su-primary-500: #2C8DE6;
  --color-su-primary-600: #2880D8;
  --color-su-primary-700: #236EC5;
  --color-su-primary-800: #1E5DB3;
  --color-su-primary-900: #164194;
  --color-su-primary: var(--color-su-primary-900);
  /* su-secondary are hues of yellow */
  --color-su-secondary-50: #FFF8E0;
  --color-su-secondary-100: #FFEDB0;
  --color-su-secondary-200: #FFE17C;
  --color-su-secondary-300: #FFD743;
  --color-su-secondary-400: #FFCC00;
  --color-su-secondary-500: #FFC300;
  --color-su-secondary-600: #FFB500;
  --color-su-secondary-700: #FFA100;
  --color-su-secondary-800: #FF8F00;
  --color-su-secondary-900: #FF6D00;
  --color-su-secondary: var(--color-su-secondary-400);
  /* su-gray are hues of gray */
  --color-su-gray-50: #F2F7FF;
  --color-su-gray-100: #E8EDFA;
  --color-su-gray-200: #DBE0EE;
  --color-su-gray-300: #C8CDDA;
  --color-su-gray-400: #A3A8B5;
  --color-su-gray-500: #828793;
  --color-su-gray-600: #5B606B;
  --color-su-gray-700: #484D58;
  --color-su-gray-800: #2B2F39;
  --color-su-gray-900: #0A0F19;
  --color-su-gray: var(--color-su-gray-900);
  --color-su-gray-bg: #EDF0F2;
  /* Aus dem CD-Handbuch */
  --color-su-grau: rgb(87, 87, 86);
  --color-su-blau: var(--color-su-primary-900);
  --color-su-gelb: var(--color-su-secondary-400);
  --color-su-hellgrau: rgb(240, 240, 240);
  --color-su-hellblau: rgb(28, 155, 216);
  --color-su-mittelgrau: rgb(219, 219, 219);
}

/** Old definitions */
:root {
  --su-blue: #173F97;
  --su-blue-light: var(--amplify-colors-blue-20);
}

[data-amplify-authenticator] {
  /* Tabs */
  --amplify-components-tabs-item-active-border-color: var(--color-su-primary);
  --amplify-components-tabs-item-hover-color: var(--color-su-primary);
  --amplify-components-tabs-item-active-color: var(--color-su-primary);
  --amplify-components-tabs-item-focus-color: var(--color-su-primary);

  /* Form fields */
  --amplify-components-fieldcontrol-focus-border-color: var(--color-su-primary);

  /* "Show/hide password" button */
  --amplify-components-button-focus-color: var(--color-su-primary);
  --amplify-components-button-focus-border-color: var(--color-su-primary);
  /* Focus background-color is the same a hover */
  --amplify-components-button-hover-color: var(--color-su-primary);
  --amplify-components-button-hover-border-color: var(--color-su-primary);
  --amplify-components-button-hover-background-color: white;
  --amplify-components-button-active-background-color: var(--su-blue-light);

  /* "Forgot password" button */
  --amplify-components-button-link-color: var(--color-su-primary);
  --amplify-components-button-link-hover-color: var(--color-su-primary);
  --amplify-components-button-link-active-color: var(--color-su-primary);
  --amplify-components-button-link-focus-color: var(--color-su-primary);
  --amplify-components-button-link-hover-background-color: var(--su-blue-light);
  --amplify-components-button-link-active-background-color: var(--su-blue-light);
  --amplify-components-button-link-focus-background-color: var(--su-blue-light);
}

.amplify-button[data-variation='primary'] {
  background-color: var(--color-su-primary) !important;
}

[data-amplify-authenticator] {
  margin-top: 1em;
}

body {
  /* su-gray-bg */
  @apply bg-[#edf0f2];
  @apply text-su-gray-600;
  font: normal normal 400 14px/28px "Plus Jakarta Sans Variable";
}

.page-heading {
  @apply text-su-gray-900;
  font: normal normal 700 20px/110% "Plus Jakarta Sans Variable";
  letter-spacing: 0.4px;
  @apply mt-4;
}

.section-heading {
  @apply text-su-gray-900;
  font: normal normal 600 16px/110% "Plus Jakarta Sans Variable";
  letter-spacing: 0.32px;
  @apply mb-4;
}

.card-heading {
  @apply text-su-gray-900;
  font: normal normal 600 14px/110% "Plus Jakarta Sans Variable";
  @apply mb-6;
}

.key-number {
  @apply text-su-gray-900;
  font: normal normal 700 24px/110% "Plus Jakarta Sans Variable";
}

h2 {
  @apply text-su-gray-900;
  font: normal normal 600 14px/15.4px "Plus Jakarta Sans Variable";
  @apply mb-4;
}

a:hover {
  @apply !text-su-primary-700;
}

th {
  @apply font-semibold;
  @apply text-start;
}

table:not(.row-headers) tr th {
  @apply border-b border-su-gray-400;
}

.psi-text-with-tooltip {
  border-bottom: 1px dashed var(--color-su-primary);
}

.breadcrumbs {
  font-size: 80%;
}

.breadcrumbs a {
  color: #888;
}

.reports-link {
  padding: 10px;
  font-size: 100%;
  cursor: pointer;
}