.daily-chart {
    max-height: 350px;
}

table.daily-report {
    border-collapse: collapse;
    border-bottom: 2px solid #888;
    margin-bottom: 1em;
}

table.daily-report th {
    border-bottom: 2px solid #888;
    hyphens: auto;
}

table.daily-report td {
    border-bottom: 1px solid #ccc;
    /* padding-left: 15px; */
    text-align: center;
}
