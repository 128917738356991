[data-amplify-authenticator] {
  @apply !bg-white;
  max-width: 50vw !important;
  min-width: 50vw !important;
  @apply !w-1/2 h-full;
}

[data-amplify-authenticator] [data-amplify-router] {
  @apply !shadow-none;
  @apply !border-0;
}

[data-amplify-authenticator] [data-amplify-router] .amplify-tabs__list {
  @apply !hidden !border-0;
}

.call-to-signIn {
  @apply text-su-gray-900;
  font: normal normal 700 26px/120% "Plus Jakarta Sans Variable";
  letter-spacing: -0.26px;
}

[data-amplify-authenticator] button.amplify-button--primary {
  @apply bg-su-primary-900;
}

[data-amplify-authenticator] button.amplify-button--link {
  @apply text-su-primary-900;
  font: normal normal 400 14px/150% "Plus Jakarta Sans Variable";
  letter-spacing: 0.28px;
}

[data-amplify-footer] {
  @apply !text-start;
}