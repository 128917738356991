.back-link-container {
  @apply pt-5 pl-6;
  @apply mb-9;
}

.back-link-container a {
  @apply text-su-primary-900;
  font: normal normal 400 14px/21px "Plus Jakarta Sans Variable";
}

.machine-header {
  @apply ml-10;
  @apply mb-7;
  /* mb-7 == 28px; layout says 40px and Tabs.Item below has 12px padding-top. */
}

.machine-header .machine-image {
  @apply w-24 h-24 rounded-full border border-[#c8cdda];
}

.machine-header .machine-serial {
  @apply text-su-gray-900;
  font: normal normal 700 26px/120% "Plus Jakarta Sans Variable";
  letter-spacing: -0.26px;
}

.machine-header .machine-family {
  @apply text-su-gray-600;
  font: normal normal 400 16px/110% "Plus Jakarta Sans Variable";
  letter-spacing: 0.32px;
}

.machine-page-wrapper .amplify-tabs__list {
  @apply pl-8;
}

.machine-page-wrapper .amplify-tabs__item {
  @apply px-6;
  @apply !text-su-gray-600;
  font: normal normal 600 14px/110% "Plus Jakarta Sans Variable";
}

.machine-page-wrapper .amplify-tabs__item--active {
  @apply !text-su-primary-900 border-su-primary-900;
}